/* Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}

.feather {
  content: "";
  z-index: 3;
  display: block;
  position: absolute;
  top: 0;
  height: 1.5rem;
  width: 32px;
  pointer-events: none;
}

.feather__beginning {
  left: 0;
  background: linear-gradient(90deg, rgba(24, 24, 27, 1) 0%, rgba(24, 24, 25, 0) 100%);
}

.feather__end {
  right: 0;
  background: linear-gradient(90deg, rgba(24, 24, 27, 0) 0%, rgba(24, 24, 25, 1) 100%);
}