body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
  Generated with Josh Comeau's shadow generator:
  https://www.joshwcomeau.com/shadow-palette/
*/
:root {
  --shadow-color: 240deg 6% 3%;
  --shadow-elevation-low:
    0.4px 0.7px 1.2px hsl(var(--shadow-color) / 0),
    0.9px 1.7px 2.9px hsl(var(--shadow-color) / 0.35),
    2.1px 3.7px 6.4px hsl(var(--shadow-color) / 0.69);
  --shadow-elevation-medium:
    0.4px 0.7px 1.2px hsl(var(--shadow-color) / 0),
    1.5px 2.7px 4.6px hsl(var(--shadow-color) / 0.09),
    2.6px 4.7px 8.1px hsl(var(--shadow-color) / 0.19),
    4.4px 7.9px 13.6px hsl(var(--shadow-color) / 0.28),
    7.6px 13.4px 23.1px hsl(var(--shadow-color) / 0.38);
  --shadow-elevation-high:
    0.4px 0.7px 0.9px hsl(var(--shadow-color) / 0.24),
    2.1px 3.7px 4.9px -0.3px hsl(var(--shadow-color) / 0.24),
    3.6px 6.5px 8.6px -0.5px hsl(var(--shadow-color) / 0.25),
    5.5px 9.8px 13px -0.8px hsl(var(--shadow-color) / 0.25),
    7.9px 14.1px 18.7px -1px hsl(var(--shadow-color) / 0.26),
    11.2px 20px 26.5px -1.3px hsl(var(--shadow-color) / 0.26),
    15.8px 28.1px 37.2px -1.5px hsl(var(--shadow-color) / 0.26),
    21.9px 39.1px 51.8px -1.8px hsl(var(--shadow-color) / 0.27),
    30px 53.4px 70.7px -2px hsl(var(--shadow-color) / 0.27),
    40.2px 71.7px 94.9px -2.3px hsl(var(--shadow-color) / 0.28);
}